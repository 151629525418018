import React from 'react'
import './whyserolix.css'
const WhySerolix = () => {
  return (
    <div>

      <div class="responsive-container-block big-container">
        <div class="responsive-container-block container">
          <div class="responsive-container-block">
            <div class="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-6 wk-ipadp-12 img-one">
              <img alt="golden-lines.png" class="image-block bg-image" src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/golden%20lines.png" />
            </div>
            <div class="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-6 wk-ipadp-12 content-one">
              <p class="text-blk section-head">
                Choose Success, Choose Us
              </p>
              <p class="text-blk section-text">
                We don't just provide solutions; we create success stories. In this ever-changing world of
                work, we stand as your partner with our innovation for businesses and customers looking for
                change.

              </p>

              <p class="text-blk section-text">
                Our you-centric approach and our dedicated team provide quality services through quality
                solutions. Our skilled and knowledgeable professionals will precisely deliver your business
                needs by combining expertise and personalised solutions—partner with us to embrace a
                future of triumph.

              </p>
              <p class="text-blk section-text">
                When your goal matters, the choice becomes clear
              </p>
              <p class="text-blk section-text">

                <b>Join us to create successful stories!</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhySerolix
import React from 'react'

const Projects = () => {
  return (
    <div class="d-flex justify-content-center align-items-center min-vh-100">
    <div>
      <p style={{color:"#ffcd05", fontSize:"40px"}}>Coming Soon</p>
    </div>
  </div>
  )
}

export default Projects
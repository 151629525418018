import React from 'react'

const Career = () => {
  return (
    <div className=' justify-content-center align-items-center min-vh-100 p-5'>
      <div>
        <h1>Make your career with Selorix</h1>
      </div>
      <div className='openings p-5'>
        <h3 className='text-center'>We have openings for the following roles</h3>
        <div className='row justify-content-center align-items-center p-2'>
          <div className='col-md-4 p-2'>
            <div className="card">
              <img className="card-img-top" src="https://media.fourdayweek.io/files/2022-the-reality-of-being-a-staff-software-engineer-salary-workload-and-how-to-get-started-eVOcC.jpeg" alt="Card image cap" style={{ height: "200px" }} />
              <div className="card-body">
                <h5 className="card-title">Software Engineering Intern</h5>
                <p className="card-text">We're offering exciting software engineering internships for aspiring tech talents. Join us in building the future of tech!</p>
              </div>
            </div>
          </div>
          <div className='col-md-4 p-2'>
            <div className="card">
              <img className="card-img-top" src="https://apprenticeteam.org/wp-content/uploads/2022/12/pexels-yan-krukov-8867434-1024x683.jpg" alt="Card image cap" style={{ height: "200px" }} />
              <div className="card-body">
                <h5 className="card-title">Customer Care Specialist</h5>
                <p className="card-text">Join our customer care team as an expert! Help us provide exceptional service and support to our valued customers. Apply today!</p>
              </div>
            </div>
          </div>
          <div className='col-md-4 p-2'>
            <div className="card">
              <img className="card-img-top" src="https://www.sciencebuddies.org/WdTLQtJ3Y_eXfwAqRO5_5KT46aI=/640x480/-/https/careerdiscovery.sciencebuddies.org/cdn/Files/18382/4/film-editor.jpg" alt="Card image cap" style={{ height: "200px" }} />
              <div className="card-body">
                <h5 className="card-title">Video Editor</h5>
                <p className="card-text">Calling all talented video editors! Join our creative team to craft compelling visuals and tell engaging stories. Apply now!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h3>Fill Out Form</h3>
      <form className='justify-content-center align-items-center p-5 border'>
        <div class="form-group row p-2">
          <label for="staticEmail" class="col-sm-2 col-form-label" style={{ textAlign: "Left" }}>Name:</label>
          <div class="col-sm-10">
            <input type="text" class="form-control" id="staticEmail" placeholder="Name" />
          </div>
        </div>
        <div class="form-group row p-2">
          <label for="staticEmail" class="col-sm-2 col-form-label" style={{ textAlign: "Left" }}>Email:</label>
          <div class="col-sm-10">
            <input type="text" class="form-control" id="staticEmail" placeholder="Email" />
          </div>
        </div>
        <div class="form-group row p-2">
          <label for="inputPassword" class="col-sm-2 col-form-label" style={{ textAlign: "Left" }}>Phone Number:</label>
          <div class="col-sm-10">
            <input type="password" class="form-control" id="inputPassword" placeholder="Password" />
          </div>
        </div>
        <div class="form-group row p-2">
          <label for="inputPassword" class="col-sm-2 col-form-label" style={{ textAlign: "Left" }}>Alternate Number:</label>
          <div class="col-sm-10 p-2">
            <input type="password" class="form-control" id="inputPassword" placeholder="Alternate Phone Number" />
          </div>
        </div>
        <div class="form-group row p-2">
          <label for="inputPassword" class="col-sm-2 col-form-label" style={{ textAlign: "Left" }}>Resume:</label>
          <div class="col-sm-10 p-2">
            <input type="file" id="pdf-file" accept=".pdf" class="form-control" />
          </div>
        </div>
        <div class="form-group row p-2">
          <label for="inputPassword" class="col-sm-2 col-form-label" style={{ textAlign: "Left" }}>Cover Letter:</label>
          <div class="col-sm-10 p-2">
            <input type="file" id="pdf-file" accept=".pdf" class="form-control" />
          </div>
        </div>
        <div className='p-2'>
          <button type="submit" class="btn"  style={{backgroundColor:"#ffcd05"}}>Submit</button>
        </div>
      </form>

    </div>
  )
}

export default Career
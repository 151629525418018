import React from "react";
import Photo from "../photos/homeImage.jpg";
import Card from "../components/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Mission from "../components/Mission";
import Logo from "../photos/247icon.png";
import LogoPrice from "../photos/fairPricelogo.png";
import LogoProfessional from "../photos/professionalIcon.png";
import LogoAward from "../photos/awardIcon.png";
import { Link } from "react-router-dom";
import CardCarousel from "../components/cardCrousel";
import ContactUs from "../components/contactUs";
import Testimonial from "../components/Testimonial";
import video from '../photos/home.mp4'
import PdfDownload from "../components/PdfDownload";
const Home = () => {
    const buttonStyle = {
        backgroundColor: '#ffcd05',
        color: 'white', // Text color
        padding: '10px 20px', // Add more styling as needed
        border: 'none',
        cursor: 'pointer',
      };
    return (
        <div>
            <div className="card text-dark justify-content-center">


                <video autoPlay loop muted id="video" style={{ width: "100%", filter: "brightness(30%)" }}>
                    <source src={video} type="video/mp4" />
                </video>

                <div className="card-img-overlay d-flex justify-content-center align-items-center flex-column col-md-5 mx-auto">
                    <h5 className="card-title fs-1" style={{ color: "white" }}>
                        <b>Welcome to Selorix</b>
                    </h5>
                    <p className="card-text fs-5 pt-3" style={{ color: "white" }}>
                        Where Business{" "}
                        <span style={{ color: "#ffcd05" }}>
                            Innovates. Outsource. Thrive
                        </span>
                    </p>
                </div>
            </div>







            <div className="">

                <div>
                    <div class="testimonials text-center">

                        <div class="container">
                            <div class="row">
                                <div class="col-md-6 col-lg-3">
                                    <div class="card border-light bg-light text-center">
                                        <i class="fa fa-solid fa-award fa-3x card-img-top rounded-circle" aria-hidden="true"></i>
                                        <div class="card-body blockquote">
                                            <h3>Award Winning</h3>
                                            <p class="card-text">We are an
                                                award-winning
                                                company that takes
                                                pride in providing
                                                top-notch BPO
                                                services for our
                                                clients.</p>

                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-lg-3">
                                    <div class="card border-light bg-light text-center">
                                        <i class="fa fa-solid fa-user-tie fa-3x card-img-top rounded-circle" aria-hidden="true"></i>
                                        <div class="card-body blockquote">
                                            <h3>Professional Staff</h3>
                                            <p class="card-text">Our dedicated team of experts will help provide the best tailored solutions for your unique business needs.</p>

                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-lg-3">
                                    <div class="card border-light bg-light text-center">

                                        <i class="fa fa-solid fa-money-bill fa-3x card-img-top rounded-circle" aria-hidden="true"></i>
                                        <div class="card-body blockquote">
                                            <h3>Fair Prices</h3>
                                            <p class="card-text">We provide our
                                                services on a
                                                cost-effective budget
                                                without compromising
                                                on quality and
                                                efficiency.</p>

                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-lg-3">
                                    <div class="card border-light bg-light text-center">

                                        <i class="fa fa-solid fa-clock fa-3x card-img-top rounded-circle" aria-hidden="true"></i>
                                        <div class="card-body blockquote">
                                            <h3>24/7 Support</h3>
                                            <p class="card-text">We provide 24/7
                                                assistance for our
                                                clients to resolve any
                                                available inquiries,
                                                issues, and guidance.</p>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

            </div>




            

            <div>
                <div class="responsive-container-block bigContainer">
                    <div class="responsive-container-block Container bottomContainer">
                        <div className="ultimateImg" style={{ padding: "50px" }}>
                            <img class="mainImg" src="https://media.gettyimages.com/id/1363823243/photo/service-provider-satisfaction-ratings-and-evaluations-customer-service-and-satisfaction.jpg?s=612x612&w=0&k=20&c=0uHSq8nHb7WVVYDFxAadmoimVRqkum2FnfuB1tQg1A0=" />
                            <div class="purpleBox">
                                <p class="purpleText" style={{ fontSize: "16px" }}>
                                    We are getting top ratings by our clients.
                                </p>
                                <img class="stars" src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/mp5.svg" />
                            </div>
                        </div>

                        <div class="allText bottomText">
                            <p class="text-blk headingText">
                                About Selorix
                            </p>

                            <p class="text-blk subHeadingText">
                                Your Vision Powered By Our Passion
                            </p>
                            <p class="text-blk description" style={{ textAlign: "left", fontSize: "16px" }}>
                                Selorix is India's dedicated Business Processing Outsource(BPO) Services provider.
                                Our team of true professionals believe in transforming challenges into opportunities,
                                crafting excellence with every interaction. The core mission of our services is to
                                enhance not just the business experience but also to foster growth.Welcome to the world where our passion propels your business success. Join us on a
                                journey where possibilities are limitless, powered by the dynamic collaboration of your
                                vision and our passion.
                            </p>
                        </div>
                        <div className="p-5">
                            <Link to="/About" className="explore">
                                More Info
                            </Link>
                        </div>

                    </div>
                </div>
            </div>
            <div className="border p-5">


                <div class="text-blk subHeadingText" style={{ textAlign: "center", fontSize: "20px" }}>
                    <p>Selorix Services is a india venture of We-PRO RCM Solutions which completely deal in US healthcare.</p>
                </div>
                <PdfDownload style={buttonStyle} />
            </div>
            <div className="service p-5">
                <section className="d-flex justify-content-center align-items-center flex-column">
                    <div className="rowi p-2">
                        <h2 class="section-heading">What We Offer</h2>
                    </div>
                    <div class="row">
                        <div class="columni">
                            <div class="cardi">
                                <div class="icon-wrapperi">
                                    <i class="fas fa-hammer"></i>
                                </div>
                                <h3 className="hr">
                                    Information Technology-Enabled Services (ITES) BPO
                                </h3>
                                <p className="pr">
                                    Our Experts team will provide the best ITES solutions to
                                    improve your business operations.
                                </p>
                            </div>
                        </div>
                        <div class="columni">
                            <div class="cardi">
                                <div class="icon-wrapperi">
                                    <i class="fas fa-brush"></i>
                                </div>
                                <h3 className="hr">Knowledge Process Outsourcing (KPO)</h3>
                                <p className="pr">
                                    Make better decisions to gain a competitive edge with our KPO
                                    solutions.
                                </p>
                            </div>
                        </div>
                        <div class="columni">
                            <div class="cardi">
                                <div class="icon-wrapperi">
                                    <i class="fas fa-wrench"></i>
                                </div>
                                <h3 className="hr">Legal Process Outsourcing (LPO)</h3>
                                <p className="pr">
                                    Our LPO experts will help you get the legal help you need
                                    without breaking the Bank.
                                </p>
                            </div>
                        </div>
                        <div class="columni">
                            <div class="cardi">
                                <div class="icon-wrapperi">
                                    <i class="fas fa-broom"></i>
                                </div>
                                <h3 className="hr">Research Process Outsourcing (RPO)</h3>
                                <p className="pr">
                                    Stay ahead of the competition with our cutting-edge research
                                    solutions
                                </p>
                            </div>
                        </div>
                        <div class="columni">
                            <div class="cardi">
                                <div class="icon-wrapperi">
                                    <i class="fas fa-truck-pickup"></i>
                                </div>
                                <h3 className="hr">Travel</h3>
                                <p className="pr">
                                    Revolutionise your travel operations with our adequate support
                                    tailored just for your travel industry.
                                </p>
                            </div>
                        </div>
                        <div class="columni">
                            <div class="cardi">
                                <div class="icon-wrapperi">
                                    <i class="fas fa-plug"></i>
                                </div>
                                <h3 className="hr">Customer Interaction Services</h3>
                                <p className="pr">
                                    Achieve seamless business excellence through customised
                                    customer interaction
                                </p>
                            </div>
                        </div>
                        <div class="columni">
                            <div class="cardi">
                                <div class="icon-wrapperi">
                                    <i class="fas fa-plug"></i>
                                </div>
                                <h3 className="hr">Back-Office Transactions</h3>
                                <p className="pr">
                                    Elevate your operational efficiency and productivity for your
                                    business.
                                </p>
                            </div>
                        </div>
                        <div class="columni">
                            <div class="cardi">
                                <div class="icon-wrapperi">
                                    <i class="fas fa-plug"></i>
                                </div>
                                <h3 className="hr"> IT and Software Operations</h3>
                                <p className="pr">
                                    Enhance efficiency, scalability, and cost-effectiveness
                                    through outsourcing expertise.
                                </p>
                            </div>
                        </div>
                        <div class="columni">
                            <div class="cardi">
                                <div class="icon-wrapperi">
                                    <i class="fas fa-plug"></i>
                                </div>
                                <h3 className="hr">Finance and Accounting Services</h3>
                                <p className="pr">
                                    Maximise your profits with seamless book-keeping, accurate
                                    reporting, and structured financial insights for growth.
                                </p>
                            </div>
                        </div>
                        <div class="columni">
                            <div class="cardi">
                                <div class="icon-wrapperi">
                                    <i class="fas fa-plug"></i>
                                </div>
                                <h3 className="hr">Human Resource Services</h3>
                                <p className="pr">
                                    Elevate your HR operations with streamlined hiring and boost
                                    employee engagement to thrive your businesses.
                                </p>
                            </div>
                        </div>
                        <div class="columni">
                            <div class="cardi">
                                <div class="icon-wrapperi">
                                    <i class="fas fa-plug"></i>
                                </div>
                                <h3 className="hr">Knowledge Services</h3>
                                <p className="pr">
                                    Make smarter decisions with our data-driven insights.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div className="team d-flex flex-column justify-content-center align-items-center p-4">
                <div style={{ maxWidth: "75%" }}>
                    <h1 className="p-2 text-blk subHeadingText">
                        Introducing our pioneer team of Selorix.
                    </h1>
                    <h4 className="text-blk description" style={{ fontSize: "17px", maxWidth: "800px" }}>
                        A team with unmatched expertise and innovation, transforming
                        businesses into successstories with their knowledge and skills,
                        setting new industry standards.
                    </h4>
                </div>
                <div className="pb-5">
                    <CardCarousel />
                </div>
            </div>
            <ContactUs />
            <div className="pb-5">
                <Testimonial />
            </div>

        </div>
    );
};

export default Home;


import Footer from './components/Footer';
import Header from './components/Header';
import Navbar from './components/Navbar';
import About from './screens/About';
import { useState } from 'react';
import './App.css'
import Home from './screens/Home';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import WhySerolix from './screens/WhySerolix';
import Service from './screens/Service';
import Contact from './screens/Contact';
import Career from './screens/Career';
import Projects from './screens/Projects';
function App() {
  const [time, settime] = useState(false);
  setTimeout(() => {
    settime(true);
  }, "2000");
  return (

    <div className="App">
      
      <BrowserRouter>

          
          <div className='sticky-top'>
            <Navbar />
          </div>
          <Routes>
            <Route exact path='/' element={< Home />}></Route>
            <Route exact path="About" element={<About />} />
            <Route exact path="whyserolix" element={<WhySerolix />} />
            <Route exact path="Services" element={<Service/>} />
            <Route exact path="Contact" element={<Contact/>} />
            <Route exact path="Career" element={<Career/>} />
            <Route exact path="Project" element={<Projects/>} />
          </Routes>
          <Footer />

        
      </BrowserRouter>

    </div>
  );
}

export default App;

import React from 'react';
import Photo from '../photos/aboutPhoto.jpg';
import Card from '../components/Card';
import CardCarousel from '../components/cardCrousel';
import './about.css'
import Mission from '../components/Mission';
import PdfDownload from '../components/PdfDownload';
const About = () => {
    return (
        <div>
            <div className="text-dark justify-content-center p-5">
                
                <div className=" d-flex justify-content-center align-items-center flex-column col-md-5 mx-auto">
                    <h5 className="fs-1" style={{ color: "black" }}><b>About Serolix</b></h5>
                </div>
            </div>
            <div>
                <div class="responsive-container-block bigContainer">
                    <div class="responsive-container-block Container bottomContainer">
                        <div className="ultimateImg">
                            <img class="mainImg" src="https://media.gettyimages.com/id/1363823243/photo/service-provider-satisfaction-ratings-and-evaluations-customer-service-and-satisfaction.jpg?s=612x612&w=0&k=20&c=0uHSq8nHb7WVVYDFxAadmoimVRqkum2FnfuB1tQg1A0="  />
                            <div class="purpleBox">
                                <p class="purpleText" style={{fontSize:"16px"}}>
                                    We are getting top ratings by our clients.
                                </p>
                                <img class="stars" src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/mp5.svg" />
                            </div>
                        </div>
                        <div class="allText bottomText">
                            <p class="text-blk subHeadingText">
                                Your Vision Powered By Our Passion
                            </p>
                            <p class="text-blk description" style={{ textAlign: "left" , fontSize:"16px" }}>
                                Selorix is India's dedicated Business Processing Outsource(BPO) Services provider.
                                Our team of true professionals believe in transforming challenges into opportunities,
                                crafting excellence with every interaction. The core mission of our services is to
                                enhance not just the business experience but also to foster growth.Welcome to the world where our passion propels your business success. Join us on a
                                journey where possibilities are limitless, powered by the dynamic collaboration of your
                                vision and our passion.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='border p-5'>


                <div class="text-blk subHeadingText" style={{ textAlign: "center", fontSize: "20px" }}>
                    <p>Selorix Services is a india venture of We-PRO RCM Solutions which completely deal in US healthcare.</p>
                </div>
                <PdfDownload/>
            </div>
            <Mission/>
            <div className='team d-flex flex-column justify-content-center align-items-center p-4'>
                <div style={{ maxWidth: "75%" }}>
                    <h1 className='p-2 text-blk subHeadingText' >Introducing our pioneer team of Selorix.</h1>
                    <p className='text-blk description' style={{fontSize:"17px" , maxWidth:"800px"}}>A team with unmatched expertise and innovation, transforming businesses into successstories with their knowledge and skills, setting new industry standards.</p>
                </div>
                <div className='pb-5'>
                    <CardCarousel />
                </div>


            </div>
        </div>
    );
};

export default About;

import React from 'react'
import { saveAs } from 'file-saver';
const PdfDownload = () => {
    const pdfPath = process.env.PUBLIC_URL + '/pdfa.pdf';
    const buttonStyle = {
        backgroundColor: '#ffcd05',
        color: 'white', // Text color
        padding: '10px 20px', // Add more styling as needed
        border: 'none',
        cursor: 'pointer',
      };

  return (
    <div>
      <div>
        <a href={pdfPath} download="WE-Pro RCM Solution LLC.pdf">
          <button style={buttonStyle}>WE-Pro RCM Solution LLC</button>
        </a>
      </div>
    </div>
  );
}

export default PdfDownload
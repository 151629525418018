import React from 'react';
import './navbar.css';
import Logo from '../photos/SS.png';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const navItemStyle = {
        fontSize: '13px', // Adjust the font size as needed
    };
    const navbarStyle = {
        backgroundColor: 'rgba(255, 255, 255, 1)', // Adjust the alpha value for the desired opacity
    };

    return (
        <div>
            <nav className="navbar navbar-expand-lg border navbar-light" id="nav1"  style={navbarStyle}>
                <div className="container-fluid">
                    <Link to='/' className='px-2'><img src={Logo} alt="logo" style={{ height: "75px", paddingRight: "20px" }} /></Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item" style={navItemStyle}>
                                <Link className="nav-link active fs-5 p-4" aria-current="page" to="/" ><span style={{ fontSize: "17px" }}>Home</span></Link>
                            </li>
                            <li className="nav-item" style={navItemStyle}>
                                <Link className="nav-link active fs-5 p-4" to="/About"><span style={{ fontSize: "17px" }}>About</span></Link>
                            </li>
                            <li className="nav-item" style={navItemStyle}>
                                <Link className="nav-link active fs-5 p-4" to="/Services"><span style={{ fontSize: "17px" }}>Service</span></Link>
                            </li>
                            <li className="nav-item" style={navItemStyle}>
                                <Link className="nav-link active fs-5 p-4" aria-disabled="true" to="/Project"><span style={{ fontSize: "17px" }}>Projects</span></Link>
                            </li>
                            <li className="nav-item" style={navItemStyle}>
                                <Link className="nav-link active fs-5 p-4" to="/whyserolix"><span style={{ fontSize: "17px" }}>Why Selorix</span></Link>
                            </li>
                            <li className="nav-item" style={navItemStyle}>
                                <Link className="nav-link active fs-5 p-4" aria-disabled="true" to="/Career"><span style={{ fontSize: "17px" }}>Career</span></Link>
                            </li>
                            <li className="nav-item" style={navItemStyle}>
                                <Link className="nav-link active fs-5 p-4" aria-disabled="true" to="/Contact"><span style={{ fontSize: "17px" }}>Contact</span></Link>
                            </li>
                        </ul>
                    </div>
                    <div class=" d-flex flex-col my-2 my-lg-0">
                        <div className='p-2'>
                            <a style={{ color: "black" }} href="mailto:info@selorixservices.com"><i class="fas fa-envelope me-3 "></i> info@selorixservices.com</a>
                        </div>
                        <div className='p-2'>
                             <a style={{ color: "black" }} href="tel:+91 9821676419"><i class="fas fa-phone me-3  "></i> 9821676419</a>
                        </div>
                        
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Navbar;

import React from 'react';
import Card from './Card'; // Assuming you have a Card component
import photo1 from '../photos/photo1.jpg'
import photo2 from '../photos/photo2.jpg'
import photo3 from '../photos/photo3.jpg'
const CardCarousel = () => {
  return (
    <div className="card-carousel">
      <div id="cardCarousel" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="row">
              <div className="col-md-6">
                <Card image={photo1} name="SHAAN"  />
              </div>
              
              
            </div>
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#cardCarousel" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#cardCarousel" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default CardCarousel;

import React from 'react'
import './service.css'
const Service = () => {
    return (
        <div className='service p-5'>
            <section className='d-flex justify-content-center align-items-center flex-column'>
                <h2 className='p-5'>What We Offer</h2>
                <div class="row">
                    <div class="columni">
                        <div class="cardi">
                            <div class="icon-wrapperi">
                                <i class="fas fa-hammer"></i>
                            </div>
                            <h3 className='hr'>Transform Your Business into a Success Story</h3>
                            <p className='pr'>
                                Your satisfaction is our main priority, so we offer you the ultimate cost-effective Information
                                technology-enabled services (ITES) BPO solution for your business needs. Our experts, who
                                are well-skilled in various industries, will help you navigate your way up with a customised
                                solution plan.
                                At Selorix, we are vital in providing clients with the best information technology-enabled services
                                nationwide. We have a team of professionals who are experts in handling responsibility
                                seamlessly, whether production support analysts, service desk analysts, or IT analysts.

                                Join the team of happy customers with Selorix, who will help boost your business and
                                operational efficiency through outsourcing.
                            </p>
                        </div>
                    </div>
                    <div class="columni">
                        <div class="cardi">
                            <div class="icon-wrapperi">
                                <i class="fas fa-brush"></i>
                            </div>
                            <h3 className='hr'>Intelligent Strategies For Smoother Savings</h3>
                            <p className='pr'>
                                We all know the challenges businesses face in this recent time. The thoughts about the
                                expenses of hiring more people on board can stress you out.

                                As a business, whether big or small, there will come a time when you need more ideas and
                                innovative technology to have better outcomes, so you rely upon our experts in KPO. We the
                                Selorix will assist you in building personalised strategies that your organisation needs. This way,
                                you save the cost more effectively.

                                Partner with our experts today and effectively achieve your business target and customer

                                demand with our reliable and affordable knowledge-based process.

                            </p>
                        </div>
                    </div>
                    <div class="columni">
                        <div class="cardi">
                            <div class="icon-wrapperi">
                                <i class="fas fa-wrench"></i>
                            </div>
                            <h3 className='hr'>Innovate Your Legal Landscape with Our LPO Services</h3>
                            <p className='pr'>
                                At Selorix, you can save more money and improve your legal operation with the help of our
                                experienced legal experts. Get quality services at an affordable price through artificial
                                intelligence (AI) and other technology solutions to improve the efficiency and productivity of your
                                legal processes.

                                Choose our team of professionals to help draft patent applications, legal agreements, and legal
                                research per your business needs. Trust us to help you achieve your long-term goal through our
                                expertise.

                                For value-driven legal process outsourcing solutions, contact us!
                            </p>
                        </div>
                    </div>
                    <div class="columni">
                        <div class="cardi">
                            <div class="icon-wrapperi">
                                <i class="fas fa-broom"></i>
                            </div>
                            <h3 className='hr'>Stay Ahead of the Competition with our RPO
                                solutions.</h3>
                            <p className='pr'>
                                Attract the right people, technology, ideas, and your ability to get talented individuals with the
                                help of our research process outsourcing solutions.
                                Get a customised plan by partnering with our RPO professionals, who will provide in-depth
                                market insights. Moreover, you can rely on us to deliver game-changing tactics that meet your
                                budget and time frames.

                                For quick results, book an appointment now!
                            </p>
                        </div>
                    </div>
                    <div class="columni">
                        <div class="cardi">
                            <div class="icon-wrapperi">

                                <i class="fas fa-truck-pickup"></i>
                            </div>
                            <h3 className='hr'>Revolutionise your travel operations with Our Travel
                                Solutions.</h3>
                            <p className='pr'>
                                Discover a seamless travel experience with our world-class travel BPO services. From
                                reservation to customer care, our personalised solutions will make your travel
                                memorable.

                                At Selorix, our dedicated team will curate meticulously in solving the complexities while
                                you focus on making memories. We are not just your travel support system but your
                                travel partner, who will be there for you 24/7.

                                Let us be your compass in the world of travel with our elevated travel solutions.

                            </p>
                        </div>
                    </div>
                    <div class="columni">
                        <div class="cardi">
                            <div class="icon-wrapperi">
                                <i class="fas fa-plug"></i>
                            </div>
                            <h3 className='hr'>Achieve seamless business excellence Today!</h3>
                            <p className='pr'>
                                Address your customer’s expectations more sustainably and comprehensively with the
                                help of our quality customer interaction solutions. We highly prioritise building solid
                                connections with the people we work which is you will recognise by our
                                customer-centric approach.

                                Partner with our expert team, who prioritise human connection and effective
                                problem-solving to help you deliver the best customer experience solutions.
                            </p>
                        </div>
                    </div>
                    <div class="columni">
                        <div class="cardi">
                            <div class="icon-wrapperi">
                                <i class="fas fa-plug"></i>
                            </div>
                            <h3 className='hr'>Elevating Operational Efficiency & Productivity.</h3>
                            <p className='pr'>
                                Get delivered unmatched back-office transaction solutions for your organisation from
                                our experts. At Selorix, our dedicated professionals will tailor all your requirements to
                                boost your business without compromising your user experience.

                                Get quality solutions that go beyond just handling back-office tasks. Sign up with our
                                experts, who are knowledgeable in different fields, to help plan an excellent
                                result-oriented outcome for you!
                            </p>
                        </div>
                    </div>
                    <div class="columni">
                        <div class="cardi">
                            <div class="icon-wrapperi">
                                <i class="fas fa-plug"></i>
                            </div>
                            <h3 className='hr'> Experience excellence in every line of code.</h3>
                            <p className='pr'>
                                Take on a journey to elevate your business with our tailored IT Support, Software
                                Development, and Maintenance Services. Our experts will ensure that you have
                                hassle-free operations, maximum uptime, and solid cutting-edge solutions that your
                                company requires.

                                Join the leading companies who trust us for flawless IT and Software BPO Services—for
                                a future-ready transformation-Get Started Today!
                            </p>
                        </div>
                    </div>
                    <div class="columni">
                        <div class="cardi">
                            <div class="icon-wrapperi">
                                <i class="fas fa-plug"></i>
                            </div>
                            <h3 className='hr'>Maximise your profits with structured financial
                                insights for growth.</h3>
                            <p className='pr'>
                                Optimise your cash flow and lower the operating cost with our award-winning Finance
                                and Accounting Outsourcing Solutions. Our team of experts will assist finance leaders
                                in coming up with excellent, well-designed strategies to transform their finance world.

                                Powered your capital in this dynamic digital world to maximise its effectiveness and
                                efficiency while fulfilling user expectations.
                                Take a positive turn with our holistic F&A BPO services approach to navigate your next
                                step effortlessly.

                            </p>
                        </div>
                    </div>
                    <div class="columni">
                        <div class="cardi">
                            <div class="icon-wrapperi">
                                <i class="fas fa-plug"></i>
                            </div>
                            <h3 className='hr'>Run Your Business Smartly With Our Human
                                Resource Services</h3>
                            <p className='pr'>
                                In a struggle with limited HR? Our Human resource solutions experts will bring the
                                change and integrity your company requires. We offer result-driven solutions from
                                taking care of payroll to retaining employees and administrative functions
                                cost-effectively.

                                Join us in partnership with us Today, where our dedicated experts will build a road map
                                to achieve your business objectives seamlessly.
                            </p>
                        </div>
                    </div>
                    <div class="columni">
                        <div class="cardi">
                            <div class="icon-wrapperi">
                                <i class="fas fa-plug"></i>
                            </div>
                            <h3 className='hr'>Make Smarter Decisions With Our Data-Driven
                                Insights.</h3>
                            <p className='pr'>
                                Welcome to a world where we craft transformative knowledge solutions tailored to your
                                success story. Our professionals will fuel innovation, streamline operations, and
                                empower your workforce with our expertly curated knowledge services.

                                With a winning blend of technology and intellect, our dynamic team will seamlessly
                                navigate the information age, enhancing your competitive edge.

                                Get in touch with us -where knowledge meets action to seize the spotlight in the global
                                arena.

                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Service
import React from 'react'
import { Link } from 'react-router-dom'
const Footer = () => {
    return (
        <div>
            <footer class="text-center text-white" style={{backgroundColor:"black"}}>

                <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">

                    <div class="me-5 d-none d-lg-block">
                        <span>Get connected with us on social networks:</span>
                    </div>

                    <div>
                        <a href="https://www.facebook.com/profile.php?id=100095308540647" class="me-4 link-secondary text-white">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="https://www.instagram.com/selorix_services/" class="me-4 link-secondary text-white">
                            <i class="fab fa-instagram"></i>
                        </a>
                        <a href="https://www.linkedin.com/company/selorix-services-india-private-limited/" class="me-4 link-secondary text-white">
                            <i class="fab fa-linkedin"></i>
                        </a>
                    </div>

                </section>

                <section class="">
                    <div class="container text-center text-md-start mt-5">

                        <div class="row mt-3">

                            <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">

                                <h5 class="text-uppercase fw-bold mb-4" style={{color:"#ffcd05"}}>
                                    <i class="fas fa-gem me-3 " style={{color:"#ffcd05"}}></i>Selorix Services India Private Limited
                                </h5>
                                <p>
                                We are here to help your businesses thrive with our seamless outsourcing services, setting new industry benchmarks. Propels by our innovation, we're here to elevate your success, allowing you to move forward in a dynamic global market.
                                </p>
                            </div>

                            

                            <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">

                                <h5 class="text-uppercase fw-bold mb-4" style={{color:"#ffcd05"}}>
                                    Useful links
                                </h5>
                                <p>
                                    <Link to="/About" class="text-reset">About</Link>
                                </p>
                                <p>
                                    <Link to="/Services" class="text-reset">Services</Link>
                                </p>
                                <p>
                                    <Link to="/Project" class="text-reset">Projects</Link>
                                </p>
                                <p>
                                    <Link to="/whyserolix" class="text-reset">Why Selorix</Link>
                                </p>
                            </div>

                            <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">

                                <h5 class="text-uppercase fw-bold mb-4" style={{color:"#ffcd05"}}>
                                    Business Hours
                                </h5>
                                <p className='text-secondary'>
                                    Monday-Friday
                                </p>
                                <p>
                                    09:00 am - 07:00 pm
                                </p>
                                
                                <p className='text-secondary'>
                                    Saturday-Sunday
                                </p>
                                <p>
                                    Closed
                                </p>
                            </div>


                            <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">

                                <h5 class="text-uppercase fw-bold mb-4" style={{color:"#ffcd05"}}>Contact</h5>
                                <p><i class="fas fa-home me-3  text-white"></i>1222-B, 12th Floor, ITHUM TOWER, Block A, Industrial Area, Sector 62, Noida, Uttar Pradesh 201301</p>
                                <p>
                                    <i class="fas fa-envelope me-3  text-white"></i>
                                    <a style={{color:"white"}} href="mailto:info@selorixservices.com">info@selorixservices.com</a>
                                </p>
                                <p><i class="fas fa-phone me-3  text-white"></i>+91 9821676419</p>
                            </div>

                        </div>

                    </div>
                </section>



                <div class="text-center p-4" style={{backgroundColor: "rgba(0, 0, 0, 0.025)"}}>
                    <span className='px-5'>© 2023 Copyright Reserve</span> <span className='px-5' style={{color:"#ffcd05"}}>Designed By : DigiNXT Media</span>   
                </div>

            </footer>
        </div>
    )
}

export default Footer
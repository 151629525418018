import React , { useEffect , useRef } from 'react';

const Card = (props) => {
    
    return (
        <div className='p-2'>
            <div className="card" style={{ height: "400px" , width:"15rem" }}>
                <div style={{ height: "80%", overflow: "hidden" }}>
                    <img src={props.image} className="card-img-top object-fit-fill" alt="..." style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                </div>
                <div className="card-body d-flex flex-column justify-content-center align-items-center" style={{ height: "20%" }}>
                    <p className="card-text fs-4 mb-1"><b>{props.name}</b></p>
                    <p className='card-text fs-5 mb-2' style={{ color: "#1B6B93" }}>{props.title}</p>
                </div>
            </div>
        </div>

    )
}

export default Card;

import React from 'react'
import './testimonial.css'
const Testimonial = () => {
    return (
        <div>
            <div class="testimonials text-center">

                <div class="container">
                    <h3>Testimonials</h3>
                    <div class="row">
                        <div class="col-md-6 col-lg-4">
                            <div class="card border-light bg-light text-center">
                                <i class="fa fa-quote-left fa-3x card-img-top rounded-circle" aria-hidden="true"></i>
                                <div class="card-body blockquote">
                                    <p class="card-text">Staff is very supportive and  comfortable atmosphere for every collogue very nice company and good atmosphere for learning new things that how to work in a professional place.</p>
                                    <footer class="blockquote-footer"><cite title="Source Title">Faizali Khan</cite></footer>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-lg-4">
                            <div class="card border-light bg-light text-center">
                                <i class="fa fa-quote-left fa-3x card-img-top rounded-circle" aria-hidden="true"></i>
                                <div class="card-body blockquote">
                                    <p class="card-text">Staff is vry supportive nd comfortable atmosphere for every collogue....well mannered nd well maintained..Selorix service have grt opportunity for the new candidates who r hiring....</p>
                                    <footer class="blockquote-footer"><cite title="Source Title">Shailender Kumar</cite></footer>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-lg-4">
                            <div class="card border-light bg-light text-center">
                                <i class="fa fa-quote-left fa-3x card-img-top rounded-circle" aria-hidden="true"></i>
                                <div class="card-body blockquote">
                                    <p class="card-text">Selorix is truly 😊 fantastic space for work and I'm genuinely impressed by the exceptionally effective management of the company. Wow, what a truly remarkable place!</p>
                                    <footer class="blockquote-footer"><cite title="Source Title">Ashish Tomar</cite></footer>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default Testimonial
import React from 'react'
import './contactUs.css'
const ContactUs = () => {
    return (
        <div>
            <div class="contact_us_6">
                <div class="responsive-container-block container">
                    <form class="form-box">
                        <div class="container-block form-wrapper">
                            <div class="mob-text">
                                <p class="text-blk contactus-head">
                                    Get in Touch
                                </p>
                                <p class="text-blk contactus-subhead">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Felis diam lectus sapien.
                                </p>
                            </div>
                            <div class="responsive-container-block" id="i2cbk">
                                <div class="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-12 wk-ipadp-12" id="i10mt-3">
                                    <p class="text-blk input-title">
                                        FIRST NAME
                                    </p>
                                    <input class="input" id="ijowk-3" name="FirstName" placeholder="Please enter first name..." />
                                </div>
                                <div class="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-12 wk-ipadp-12" id="ip1yp">
                                    <p class="text-blk input-title">
                                        EMAIL
                                    </p>
                                    <input class="input" id="ipmgh-3" name="Email" placeholder="Please enter email..." />
                                </div>
                                <div class="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-12 wk-ipadp-12" id="ih9wi">
                                    <p class="text-blk input-title">
                                        PHONE NUMBER
                                    </p>
                                    <input class="input" id="imgis-3" name="PhoneNumber" placeholder="Please enter phone number..." />
                                </div>
                                <div class="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-12 wk-ipadp-12" id="i634i-3">
                                    <p class="text-blk input-title">
                                        WHAT DO YOU HAVE IN MIND ?
                                    </p>
                                    <textarea class="textinput" id="i5vyy-3" placeholder="Please enter query..."></textarea>
                                </div>
                            </div>
                            <button class="submit-btn" id="w-c-s-bgc_p-1-dm-id-2">
                                Submit
                            </button>
                        </div>
                    </form>
                    <div class="responsive-cell-block wk-desk-7 wk-ipadp-12 wk-tab-12 wk-mobile-12" id="i772w">
                        <div class="map-part">
                            <p class="text-blk map-contactus-head" id="w-c-s-fc_p-1-dm-id">
                                Reach us at
                            </p>
                            <div class="map-box container-block">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14008.323434335009!2d77.372166!3d28.6273386!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce5b0d2dd3da3%3A0x79f2642bf492549c!2sSelorix%20Services%20India%20Private%20Limited!5e0!3m2!1sen!2sin!4v1692780225271!5m2!1sen!2sin" style={{height:"100%" , width:"100%"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs
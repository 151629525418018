import React from 'react'
import './mission.css'
const Mission = () => {
  return (
    <div>
      <div class="responsive-container-block bigContainer">
        <div class="responsive-container-block Container">
          <img class="mainImg" src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/eaboutus1.svg" style={{height:"440px"}} />
          <div class="allText aboveText">
            <p class="text-blk headingText">
              Our Mission
            </p>
            <p class="text-blk subHeadingText"style={{textAlign:"right"}}>
              We aim to be the driving force behind your success, forging excellence in our outsourcing solutions.
            </p>
            <p class="text-blk description" style={{textAlign:"right" , fontSize:"16px"}}>
              We envision a future where businesses leverage to empower their brand core and customer
              experience through our advanced solutions. By empowering our team to embody a
              harmonious blend of expertise, dedication, and teamwork, we aim to collaborate with the
              boldest innovators nationwide to transform hurdles into opportunities. From improving supply
              chains to customer service to employee experiences, we aim to achieve unmatched success!
            </p>
          </div>
        </div>
        <div class="responsive-container-block Container bottomContainer">
          <img class="mainImg" src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/xpraup2.svg" style={{height:"440px"}} />
          <div class="allText bottomText">
            <p class="text-blk headingText">
              Our Vision
            </p>
            <p class="text-blk subHeadingText" style={{textAlign:"left"}}>
              At Selorix, our mission is to redefine BPO excellence in India.
            </p>
            <p class="text-blk description" style={{textAlign:"left" , fontSize:"16px"}}>
              We are here to help your businesses thrive with our seamless outsourcing services, setting new industry benchmarks. Propels by our innovation, we're here to elevate your success, allowing you to move forward in a dynamic global market.Regardless of what you are looking for, either in-office administrative, office support or other
              business support activities, Selorix has the team and expertise you need to help achieve your
              goals.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Mission